import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-ssangyong-download-popup',
  templateUrl: './ssangyong-download-popup.component.html',
  styleUrls: ['./ssangyong-download-popup.component.less']
})
export class SsangyongDownloadPopupComponent implements OnInit, AfterViewInit {

  @Input() downloadUrlData: any;
  @Input() popupDisplayData: any;
  @Input() selectedSeriesName = "";
  modalToggle = false;
  downloadMapUrls: any = [];
  downloadLicenseUrls = [];
  urls = [];
  @Output() closePopup = new EventEmitter<boolean>();
  oemName!: string;

  constructor(
    private dataService: DataService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    for (const x of this.downloadUrlData) {
      if (x.NAME) {
        this.urls = (x.URL_DESC.split(';'))
        if (this.urls && this.urls.length > 0) {
          for (const [index, value] of this.urls.entries()) {
            this.downloadMapUrls.push({NAME: x.NAME, URL_DESC: this.urls[index]});
          }
        }
        // this.downloadMapUrls = x.URL_DESC.split(';');
      }
    }
    this.openModal();
    
  }

  openModal() {
    this.modalToggle = true;
  }

  cancel() {
    this.modalToggle = false;
    this.closePopup.emit(this.modalToggle);
  }

  ngOnDestroy(): void {
    this.downloadMapUrls = [];
    this.downloadLicenseUrls = [];
  }

  ngAfterViewInit() {
    this.changeValueIntoInnerHtml('index.cancel', 1);
  }

  changeValueIntoInnerHtml(key: string, numberInput: number) {
    const input: any = document.getElementById("cancelButton");
    if (input) {
      this.translate.get(key).subscribe((result: any) => {
        input.innerHTML = result;
        setTimeout(() => {
          input.value = input.textContent;
        }, 50);
      });
      // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      //   input.innerHTML = this.translate.instant(key);
      //   setTimeout(() => {
      //     input.value = input.textContent;
      //   }, 500);
      // });
    }
  }

  checkMatchValue(seriesName: string): boolean {
    switch (seriesName) {
      case 'Y450':
        return true;
      case 'Q250':
        return true;
      case 'E100':
        return true;
      case 'J101':
        return true;
      default:
        return false;
    }
  }

  checkMatchValueForOtherSeries(seriesName: string): boolean {
    switch (seriesName) {
      case 'Y461':
        return true;
      case 'Q261':
        return true;
      default:
        return false;
    }
  }

  getLastFourCharacter(seriesName: string) {
    return seriesName.substring(seriesName.length - 4);
  }

}
